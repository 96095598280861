/* eslint-disable react/no-array-index-key, react/no-danger */
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Calendar from "components/home/Calendar";
import Select from "react-select";
import axios from "axiosClient";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { toast } from "react-toastify";
import { scroller } from "react-scroll";
import { toBase64 } from "utils/toBase64";
import Headroom from "react-headroom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageDropdown from "components/LanguageDropdown";

const Home = () => {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(null);
  const [venues, setVenues] = useState(null);
  const [modules, setModules] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [sessionsDetails, setSessionsDetails] = useState([]);
  const [modulesForApi, setModulesForApi] = useState([]);
  const [values, setValues] = useState({});
  const [dob, setDob] = useState(null);
  const [residence, setResidence] = useState("Uzbekistan");
  const [validate, setValidate] = useState({
    emailState: "",
    passportState: "",
  });
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState(null);
  const [examUi, setExamUi] = useState(null);
  const [showExams, setShowExams] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showPinForm, setShowPinForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showRegions, setShowRegions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [image, setImage] = useState({});
  const [id, setId] = useState(null);
  const [region, setRegion] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [based, setBased] = useState("pb");
  const [showBasedOptions, setShowBasedOptions] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const partner = searchParams.get("pr");

  const { t } = useTranslation();

  const handleExamChange = (e) => {
    setExam(e.value);
    setExamUi(e.value);
    setShowRegions(false);
    setShowExams(false);
    setShowCalendar(false);
    setShowPinForm(false);
    setShowForm(false);
    if (e.value === "CEQs") {
      setShowBasedOptions(true);
    } else {
      setShowBasedOptions(false);
      setLoading(true);
      axios
        .get(
          `/api/${
            e.value === "TKT"
              ? "tkt"
              : e.value === "TRKI"
              ? "trki"
              : e.value === "Linguaskill"
              ? "ls"
              : "ceq"
          }-regions`
        )
        .then((result) => {
          let venuesList = [];
          result.data.forEach((element) => {
            venuesList.push({ label: element.name, value: element.name });
          });
          venuesList.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
          setVenues(venuesList);
          setLoading(false);
          setShowRegions(true);
        });
    }
  };

  const handleBasedChange = (e) => {
    setBased(e.value);
    setShowRegions(false);
    setShowExams(false);
    setShowCalendar(false);
    setShowPinForm(false);
    setShowForm(false);

    setLoading(true);
    axios
      .get(
        `/api/${
          e.value === "TKT"
            ? "tkt"
            : e.value === "TRKI"
            ? "trki"
            : e.value === "Linguaskill"
            ? "ls"
            : "ceq"
        }-regions?mode=${e.value}`
      )
      .then((result) => {
        let venuesList = [];
        result.data.forEach((element) => {
          venuesList.push({ label: element.name, value: element.name });
        });
        venuesList.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
        setVenues(venuesList);
        setLoading(false);
        setShowRegions(true);
      });
  };

  const handleVenueChange = (e) => {
    axios
      .get(
        `/api/${
          exam === "TKT"
            ? "tkt"
            : exam === "TRKI"
            ? "trki"
            : exam === "Linguaskill"
            ? "ls"
            : "ceq"
        }-modules?region=${e.value}&mode=${based}`
      )
      .then((result) => {
        let modulesList = [];
        result.data.forEach((element) => {
          modulesList.push({ label: element.name, value: element.name });
        });
        modulesList.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        setRegion(e.value);
        setModules(modulesList);
        setLoading(false);
        setShowCalendar(false);
        setShowPinForm(false);
        setShowForm(false);
        setShowExams(true);
      });
  };

  const handleModuleChange = (e) => {
    axios
      .get(
        `/api/${
          exam === "TKT"
            ? "tkt"
            : exam === "TRKI"
            ? "trki"
            : exam === "Linguaskill"
            ? "ls"
            : "ceq"
        }-dates?module=${e.value}&region=${region}&mode=${based}`
      )
      .then((result) => {
        let sessionList = [];
        let sessionDetailsList = {};
        result.data.forEach((element) => {
          sessionList.push(element.sessionDate);
          sessionDetailsList[element.sessionDate] = {
            low: element?.lowAvailable,
            quick: element?.fillingQuickly,
          };
        });
        // console.log(result.data);
        setModulesForApi(result.data);
        setSessions(sessionList);
        setSessionsDetails(sessionDetailsList);
        setLoading(false);
        setShowCalendar(true);
      });
  };

  const handleSelectedDay = (day) => {
    setSelectedDay(day);
    // console.log(day);
    if (day !== null) {
      setShowPinForm(true);
      setShowForm(false);
    } else {
      setShowPinForm(false);
      setValues({});
    }
  };

  const handleValuesChange = (e) => {
    if (residence === "Uzbekistan" && e.target.name === "passportId") {
      setValues({
        ...values,
        [e.target.name]: e.target.value.toUpperCase().replace(" ", ""),
      });
    } else if (residence === "Uzbekistan" && e.target.name === "phoneNumber") {
      setValues({
        ...values,
        [e.target.name]: e.target.value.replace(" ", "").replace("+", ""),
      });
    } else {
      let value = e.target.value;
      if (e.target.name === "fullName" || residence === "Other") {
        setValues({ ...values, [e.target.name]: value });
      } else if (e.target.name !== "email") {
        value = value.replace(/[^A-Za-z'`]/gi, "");
      }
      setValues({ ...values, [e.target.name]: value });
    }
    setErrorMessage("");
  };

  const handleGenderChange = (e) => {
    setValues({ ...values, gender: e.value });
  };

  const handleResidenceChange = (e) => {
    setResidence(e.value);
  };

  const validatePassportId = (e) => {
    let rex = /[A-Z]{2}[0-9]{7}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, passportState: "has-success" });
    } else {
      setValidate({ ...validate, passportState: "has-danger" });
    }
  };

  const validatePhoneNumber = (e) => {
    let rex = /998[0-9]{9}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, phoneState: "has-success" });
    } else {
      setValidate({ ...validate, phoneState: "has-danger" });
    }
  };

  const validateEmail = (e) => {
    let rex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, emailState: "has-success" });
    } else {
      setValidate({ ...validate, emailState: "has-danger" });
    }
  };

  const regionLiveOptions = [
    { label: "Andijan", value: "Andijan" },
    { label: "Bukhara", value: "Bukhara" },
    { label: "Fergana", value: "Fergana" },
    { label: "Jizzakh", value: "Jizzakh" },
    { label: "Karshi", value: "Karshi" },
    { label: "Kokand", value: "Kokand" },
    { label: "Namangan", value: "Namangan" },
    { label: "Navoi", value: "Navoi" },
    { label: "Nukus", value: "Nukus" },
    { label: "Samarkand", value: "Samarkand" },
    { label: "Sirdarya", value: "Sirdarya" },
    { label: "Termez", value: "Termez" },
    { label: "Tashkent", value: "Tashkent" },
    { label: "Urgench", value: "Urgench" },
  ];
  const handleRegionLiveChange = (e) => {
    setValues({ ...values, regionLive: e.value });
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();
    // console.log(fileType);

    if (!correctImageTypes.includes(fileType)) {
      try {
        setLoading(true);
        const result = await toBase64(file);
        let { data } = await axios.post(
          "/api/upload-file",
          {
            file: {
              extension: file.name.split(".")[file.name.split(".").length - 1],
              type: file.type,
              base64Data: result,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //set image in te state
        setImage(data);
        setValues({ ...values, passportIsImage: false });
        setLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    } else {
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/api/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setLoading(true);
      await axios.post("/api/remove-image", { image });
      setImage({});
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleGetPin = (e) => {
    e.preventDefault();
    if (residence === "Uzbekistan") {
      if (
        validate.passportState === "has-success" &&
        values.passportId &&
        dob
      ) {
        setPinLoading(true);
        axios
          .post(`/api/check-details`, {
            docNumber: values.passportId,
            dob: moment(dob).format("YYYY-MM-DD"),
          })
          .then(async (result) => {
            if (result.status === 200) {
              const bannedData = await axios.post(`/api/check-user`, {
                pinpp: result.data.pinpp,
              });
              if (bannedData.data.banned) {
                toast.error(`You are not able to register at the moment.`);
              } else if (selectedDay >= result.data.docdateend) {
                toast.error(
                  "Your passport will be expired till the exam date, you cannot register with these details."
                );
              } else {
                setLoading(false);
                setValues({
                  ...values,
                  firstName: result.data.firstName,
                  lastName: result.data.lastName,
                  pinpp: result.data.pinpp,
                  photo: result.data.photo,
                  gender: result.data.sex == 1 ? "Male" : "Female",
                });
                setShowForm(true);
              }
            } else if (result.status === 404) {
              toast.error(
                examUi === "TRKI"
                  ? "Неверный номер паспорта или дата рождения, пожалуйста, проверьте"
                  : "Passport ID or Date of Birth is wrong, please check"
              );
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              toast.error(
                examUi === "TRKI"
                  ? "Неверный номер паспорта или дата рождения, пожалуйста, проверьте"
                  : "Passport ID or Date of Birth is wrong, please check"
              );
            }
          })
          .finally(() => {
            setPinLoading(false);
          });
      }
    } else {
      setShowForm(true);
      setValues({
        ...values,
        firstName: "",
        lastName: "",
        pinpp: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let chosenSession = modulesForApi.filter(
      (one) => one.sessionDate === selectedDay
    );
    if (
      !values.firstName ||
      !values.lastName ||
      !dob ||
      !values.gender ||
      !values.phoneNumber ||
      !values.email ||
      !image?.Location
    ) {
      setErrorMessage(
        examUi === "TRKI"
          ? "Пожалуйста, заполните все поля"
          : "Please fill in all the fields"
      );
    } else if (examUi !== "TRKI" && !values.regionLive) {
      setErrorMessage("Please fill in all the fields");
    } else if (examUi === "TRKI" && !values.fullName) {
      setErrorMessage("Пожалуйста, заполните все поля");
    } else if (!agreed) {
      setErrorMessage(
        examUi === "TRKI"
          ? "Пожалуйста, ознакомьтесь с Правилами и условиями и установите флажок выше"
          : "Please read the Terms and Conditions and check the box above"
      );
    } else {
      // console.log({
      //   ...values,
      //   passportFile: image,
      //   examDate: selectedDay,
      //   examId: chosenSession[0]._id,
      //   module: chosenSession[0].module,
      //   region: chosenSession[0].region,
      //   exam: "TKT",
      // });
      axios
        .post(
          `/api/${
            exam === "TKT"
              ? "tkt"
              : exam === "TRKI"
              ? "trki"
              : exam === "Linguaskill"
              ? "ls"
              : "ceq"
          }-register`,
          {
            ...values,
            dateOfBirth: new Date(moment(dob).set("hour", 10)),
            dob: moment(dob).format("DD/MM/YYYY"),
            passportFile: image,
            examDate: selectedDay,
            examId: chosenSession[0]._id,
            module: chosenSession[0].module,
            region: chosenSession[0].region,
            exam: exam,
            partner,
            residence,
            ...(based === "cb" ? { isCb: true } : {}),
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setLoading(false);
            setShowCalendar(false);
            setShowPinForm(false);
            setShowForm(false);
            setShowExams(false);
            setShowRegions(false);
            setShowSuccess(true);
            setDob(null);
            setId(result.data.newEnquiry.enquiryId);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          } else if (result.status === 206) {
            setErrorMessage(
              examUi === "TRKI"
                ? "Вы уже зарегистрировались по этим реквизитам!"
                : "You have already registered with these details!"
            );
          } else if (result.status === 405) {
            setErrorMessage(
              examUi === "TRKI"
                ? "Извините, ячейки с этими реквизитами уже заполнены!"
                : "Sorry, the slots with these details are already filled up!"
            );
          } else {
            setErrorMessage(result.data);
          }
        });
    }
  };

  const scrollTo = (event, target) => {
    event.preventDefault();
    scroller.scrollTo(target, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100,
    });
    return false;
  };

  const onWindowClick = () => {
    setShowMobileMenu(false);
  };

  const onWindowScroll = () => {
    setShowMobileMenu(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll);
    window.addEventListener("click", onWindowClick);

    // document.body.classList.add('no-footer');
    return () => {
      window.removeEventListener("scroll", onWindowScroll);
      window.removeEventListener("click", onWindowClick);
      // document.body.classList.remove('no-footer');
    };
  }, []);

  return (
    <div className={`landing-page ${showMobileMenu ? "show-mobile-menu" : ""}`}>
      <div className="main-container">
        <div
          className="mobile-menu"
          onClick={(event) => event.stopPropagation()}
        >
          <a
            className="c-pointer"
            href="#scroll"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <div className="col-12 d-block mt-3 text-center">
              <img
                src="/static/img/vertical.png"
                alt="logo"
                className="logo-white"
              />
            </div>
          </a>
          <ul className="navbar-nav">
            {/* <li className="nav-item">
              <a
                className="c-pointer"
                href="https://examscambridge.uz/exam-dates/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TKT {t("examDates")}
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className="c-pointer"
                href="https://mock.edu-action.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TKT Mock
              </a>
            </li>
            <li className="nav-item">
              <div className="separator" />
            </li>
            <li className="nav-item">
              <a
                className="c-pointer"
                href="https://payment.edu-action.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("examsPayment")}
              </a>
            </li>
            <li className="nav-item">
              <div className="separator" />
            </li>
            <h4 className="text-center">{t("results")}</h4>
            <li className="nav-item">
              <a
                className="c-pointer"
                href="https://results.edu-action.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TKT {t("results")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="c-pointer"
                href="https://www.metritests.com/metrica/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linguaskill {t("results")}
              </a>
            </li>
            <li className="nav-item">
              <div className="separator" />
            </li>
            <li className="nav-item">
              <a
                className="c-pointer"
                href="https://celta.uz"
                target="_blank"
                rel="noopener noreferrer"
              >
                CELTA
              </a>
            </li>
            <li className="nav-item">
              <div className="separator" />
            </li>
            <li className="nav-item">
              <a className="c-pointer" href="#contactUs">
                {t("contacts")}
              </a>
            </li>
          </ul>
        </div>

        <Headroom className="landing-page-nav">
          <nav>
            <div className="container d-flex align-items-center justify-content-between">
              <div></div>
              <div className="d-flex flex-row">
                <LanguageDropdown />

                <a
                  className="mobile-menu-button mr-2"
                  href="https://t.me/Edu_Exams"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img
                    src="assets/logos/telegram.svg"
                    alt="telegram"
                    style={{ width: "20px" }}
                  /> */}
                  <i className="simple-icon-paper-plane" />
                </a>
                <a
                  className="mobile-menu-button mr-2"
                  href="https://instagram.com/edu_exams"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="simple-icon-social-instagram" />
                </a>
                <span
                  className="mobile-menu-button"
                  onClick={(event) => {
                    setShowMobileMenu(!showMobileMenu);
                    event.stopPropagation();
                  }}
                >
                  <i className="simple-icon-menu" />
                </span>
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            </div>
          </nav>
        </Headroom>
        <div className="content-container" id="home">
          <div className="section home">
            <div className="container">
              <div className="row home-row pt-2">
                <div className="col-12 d-block mt-3 text-center">
                  <img
                    src="/static/img/EA-vertical-white.png"
                    alt="logo"
                    className="logo-white"
                  />
                </div>
                <div className="col-12 ">
                  {showSuccess && (
                    <div className="home-text">
                      <div className="display-1 mt-5 pt-5">
                        {t("submittedSucces")}
                        <strong>ID# {id}</strong>.<p>{t("checkEmail")}</p>
                        <button
                          className="btn btn-light btn-xl  mb-2"
                          onClick={(e) => {
                            window.location.assign(
                              `https://payment.edu-action.uk?enquiryId=${id}`
                            );
                            // navigate(
                            //   `https://payment.edu-action.uk?enquiryId=${id}`
                            // )
                          }}
                        >
                          {t("payNow")}
                        </button>
                      </div>
                    </div>
                  )}
                  {!showSuccess && (
                    <div className="home-text">
                      <div className="display-1 mt-5 pt-5">
                        {t("mainTitle")}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="home-text px-5 text-left col-md-6">
                    <Label className="white">{t("selectExam")}</Label>
                    <Select
                      className="react-select mb-3"
                      name="venue"
                      options={[
                        { label: "TKT", value: "TKT" },
                        { label: "Linguaskill", value: "Linguaskill" },
                        {
                          label: "Cambridge English Qualifications (CEQs)",
                          value: "CEQs",
                        },
                      ]}
                      onChange={(e) => handleExamChange(e)}
                    />
                    {showBasedOptions && (
                      <>
                        <Label className="white">{t("selectBased")}</Label>
                        <Select
                          className="react-select mb-3"
                          name="based"
                          options={[
                            { label: "PB - (Paper-based)", value: "pb" },
                            { label: "CB - (Computer-based)", value: "cb" },
                          ]}
                          onChange={(e) => handleBasedChange(e)}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="home-text px-5 text-left">
                    {showRegions && (
                      <>
                        <Label className="white">{t("selectVenue")}</Label>
                        <Select
                          className="react-select mb-3"
                          name="venue"
                          options={venues}
                          onChange={handleVenueChange}
                        />
                      </>
                    )}

                    {showExams && (
                      <>
                        <Label className="white">
                          {examUi === "CEQs"
                            ? t("selectLevel")
                            : t("selectModule")}
                        </Label>
                        <Select
                          className="react-select mb-3"
                          name="module"
                          options={modules}
                          onChange={handleModuleChange}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  {showCalendar && (
                    <>
                      <Label className="white">{t("selectTestDate")}</Label>
                      <Calendar
                        dates={sessions}
                        details={sessionsDetails}
                        setSelectedDay={handleSelectedDay}
                      />
                    </>
                  )}
                </div>
                <div className="col-12" style={{ zIndex: 99 }}>
                  {showPinForm && (
                    <Form>
                      <Row>
                        <Col md={7} className="mx-auto">
                          <h3 className="white mt-4">{t("fillDetails")}</h3>
                          <div
                            className="card mt-2"
                            style={{ borderRadius: "0.75rem" }}
                          >
                            <div className="card-body text-left">
                              <FormGroup>
                                <Label>{t("residenceCountry")}</Label>
                                <Select
                                  required
                                  className="react-select mb-3"
                                  value={{
                                    label: t(residence),
                                    value: residence,
                                  }}
                                  name="residence"
                                  options={[
                                    {
                                      label: t("Uzbekistan"),
                                      value: "Uzbekistan",
                                    },
                                    {
                                      label: t("Other"),
                                      value: "Other",
                                    },
                                  ]}
                                  onChange={handleResidenceChange}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>{t("passportNo")}</Label>
                                <Input
                                  required
                                  type="text"
                                  name="passportId"
                                  valid={
                                    residence === "Uzbekistan"
                                      ? validate.passportState === "has-success"
                                      : true
                                  }
                                  invalid={
                                    residence === "Uzbekistan"
                                      ? validate.passportState === "has-danger"
                                      : false
                                  }
                                  value={values.passportId}
                                  disabled={showForm}
                                  onChange={(e) => {
                                    validatePassportId(e);
                                    handleValuesChange(e);
                                  }}
                                />{" "}
                                <FormFeedback>
                                  {t("invalidPassportNo")}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{t("dob")}</Label>
                                <DatePicker
                                  className={"form-control"}
                                  disabled={showForm}
                                  calendarIcon={
                                    <i className="simple-icon-calendar" />
                                  }
                                  clearIcon={<i className="iconsminds-close" />}
                                  dayPlaceholder="DD"
                                  monthPlaceholder="MM"
                                  yearPlaceholder="YYYY"
                                  format="dd/MM/y"
                                  minDate={
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 100
                                      )
                                    )
                                  }
                                  value={dob ? dob : null}
                                  showLeadingZeros={true}
                                  onCalendarOpen={() => {
                                    if (!dob) {
                                      setDob(
                                        new Date(
                                          new Date().setFullYear(
                                            new Date().getFullYear() - 16
                                          )
                                        )
                                      );
                                    }
                                  }}
                                  shouldOpenCalendar={({ reason }) =>
                                    reason !== "focus"
                                  }
                                  onChange={(v) => {
                                    setDob(v);
                                  }}
                                />
                              </FormGroup>

                              {!showForm ? (
                                <button
                                  type="submit"
                                  className="btn btn-light btn-xl  mb-2"
                                  disabled={pinLoading}
                                  onClick={(e) => handleGetPin(e)}
                                >
                                  {t("proceed")}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  {showForm && (
                    <Form>
                      <Row>
                        <Col md={7} className="mx-auto">
                          <div
                            className="card mt-2"
                            style={{ borderRadius: "0.75rem" }}
                          >
                            <div className="card-body text-left">
                              <FormGroup>
                                <Label>{t("firstName")}</Label>
                                <Input
                                  required
                                  type="text"
                                  name="firstName"
                                  disabled={residence === "Uzbekistan"}
                                  value={values.firstName}
                                  onChange={handleValuesChange}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>{t("lastName")}</Label>
                                <Input
                                  required
                                  type="text"
                                  name="lastName"
                                  disabled={residence === "Uzbekistan"}
                                  value={values.lastName}
                                  onChange={handleValuesChange}
                                />
                              </FormGroup>
                              {examUi === "TRKI" ? (
                                <FormGroup>
                                  <Label>
                                    Полное имя на криллийском (напр. Петров Иван
                                    Борисович)
                                  </Label>
                                  <Input
                                    required
                                    type="text"
                                    name="fullName"
                                    value={values.fullName}
                                    onChange={handleValuesChange}
                                  />
                                </FormGroup>
                              ) : null}
                              <FormGroup>
                                <Label>{t("gender")}</Label>
                                <Select
                                  required
                                  className="react-select mb-3"
                                  value={
                                    values.gender === "Male"
                                      ? {
                                          label: t("male"),
                                          value: "Male",
                                        }
                                      : {
                                          label: t("female"),
                                          value: "Female",
                                        }
                                  }
                                  isDisabled={residence === "Uzbekistan"}
                                  name="module"
                                  options={[
                                    {
                                      label: t("male"),
                                      value: "Male",
                                    },
                                    {
                                      label: t("female"),
                                      value: "Female",
                                    },
                                  ]}
                                  onChange={handleGenderChange}
                                />
                              </FormGroup>
                              {examUi === "TRKI" ? null : (
                                <FormGroup>
                                  <Label>{t("selectCertificateRegion")}</Label>
                                  <Select
                                    name="regionLive"
                                    className="react-select mb-3"
                                    options={regionLiveOptions}
                                    onChange={handleRegionLiveChange}
                                  />
                                </FormGroup>
                              )}
                              <FormGroup>
                                <Label>{t("phoneNumber")}</Label>
                                <Input
                                  required
                                  type="number"
                                  name="phoneNumber"
                                  valid={
                                    residence === "Uzbekistan"
                                      ? validate.phoneState === "has-success"
                                      : true
                                  }
                                  invalid={
                                    residence === "Uzbekistan"
                                      ? validate.phoneState === "has-danger"
                                      : false
                                  }
                                  value={values.phoneNumber}
                                  onChange={(e) => {
                                    if (residence === "Uzbekistan") {
                                      validatePhoneNumber(e);
                                    }
                                    handleValuesChange(e);
                                  }}
                                />{" "}
                                <FormFeedback>
                                  {t("invalidPhoneNumber")}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{t("email")}</Label>
                                <Input
                                  required
                                  type="email"
                                  name="email"
                                  valid={validate.emailState === "has-success"}
                                  invalid={validate.emailState === "has-danger"}
                                  value={values.email}
                                  onChange={(e) => {
                                    validateEmail(e);
                                    handleValuesChange(e);
                                  }}
                                />
                                <FormFeedback>{t("invalidEmail")}</FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{t("attachPassport")}</Label>
                                <Input
                                  type="file"
                                  name="passportFile"
                                  onChange={handleImage}
                                  disabled={loading}
                                />
                              </FormGroup>
                              <FormGroup className="ml-4">
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name="agreedTerms"
                                    onChange={() => setAgreed(!agreed)}
                                    checked={agreed}
                                  />
                                  {t("readAgreed")}
                                  <a
                                    href={
                                      examUi === "TRKI"
                                        ? "/assets/trki_regulations.pdf"
                                        : "/assets/regulations.pdf"
                                    }
                                    target="blank"
                                  >
                                    <strong>{t("termsConditions")}</strong>
                                  </a>
                                </Label>
                              </FormGroup>
                              <p className="text-danger">{errorMessage}</p>
                              <button
                                type="submit"
                                className="btn btn-light btn-xl  mb-2"
                                onClick={(e) => handleSubmit(e)}
                              >
                                {t("submit")}
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!showSuccess && <div className="section"></div>}
          <div className="section footer mb-0">
            <div className="container" style={{ zIndex: 0 }}>
              <div className="row footer-row">
                <div className="col-12 text-right">
                  <a
                    className="btn btn-circle btn-outline-semi-light footer-circle-button c-pointer"
                    href="#scroll"
                    onClick={(event) => scrollTo(event, "home")}
                  >
                    <i className="simple-icon-arrow-up" />
                  </a>
                </div>
                <div className="col-12 text-center footer-content">
                  <a
                    className="c-pointer"
                    href="#scroll"
                    onClick={(event) => scrollTo(event, "home")}
                  >
                    <div className="col-12 d-block mt-3 text-center">
                      <img
                        src="/static/img/EA-vertical-white.png"
                        alt="logo"
                        className="logo-white"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="container copyright pt-5 pb-5" id="contactUs">
              <div className="row">
                <div className="col-12" />
                <div className="col-12 text-center">
                  <p className="mb-0">
                    {t("telephone")}:{" "}
                    <a className="text-white" href="tel:998712300103">
                      +998712300103
                    </a>
                  </p>
                  <p className="mb-0">
                    <a className="text-white" href="https://t.me/tktonlinebot">
                      Telegram bot: @tktonlinebot
                    </a>
                  </p>
                  <p className="mb-0">
                    {t("address1")}
                    <br />
                    {t("address2")}
                  </p>
                  <br />
                  <p className="mb-0">
                    {new Date().getFullYear()} © Edu-Action
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
